/*---Porfolio Category
------------------------*/

.divPortfolioCategoryTitle{
  position: relative;
  outline: 0px;
  margin: auto;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}

.divPortfolioImageList{
  position: relative;
  outline: 0px;
  margin: auto;
  //display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 24px;
  border-width: 1px 0px;
  border-style: solid;

  /*background-color: rgb(10, 25, 41);
  border-color: rgba(255, 255, 255, 0.12);
  border-image: initial;*/
}