html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  color: $px-body;
  font-family: $px-font;
  line-height: $px-line-height;
  font-size: $px-font-size;
  font-weight: 400;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

.font-alt {
  font-family: $px-font-alt;
}
.text-center {
  text-align: center;
}
img {
  max-width: 100%;
}

* {
  outline: none !important;
}

a {
  color: $px-theme;
  transition: 0.3s;
  @include px-hover {
    color: darken($px-theme, 8%);
    text-decoration: none;
  }
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
mark {
  background-image: linear-gradient($px-theme, $px-theme);
  background-size: 100% 3px;
  background-repeat: no-repeat;
  background-position: 0 bottom;
  background-color: transparent;
  padding: 0;
  color: currentColor;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
.invalid-feedback {
  width: 100%;
  margin-top: 5px;
  font-size: 15px;
  color: #dc3545;
  display: block;
}
.valid-feedback {
  width: 100%;
  margin-top: 5px;
  font-size: 15px;
  color: #198754;
  display: block;
}
.scroll_up {
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: #fff;
  color: $px-theme;
  width: 42px;
  height: 45px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  font-size: 20px;
  cursor: pointer;
  &:hover {
    background: $px-theme;
    color: #fff;
    transform: translateY(-5px);
  }
  span {
    font-weight: 600;
  }
}
@media screen and (max-width: 767px) {
  .text-sm-center {
    text-align: center;
  }
}
.form-group {
  margin-bottom: 1rem;
}
textarea.form-control {
  height: auto;
}
.me-auto {
  margin-right: auto !important;
}
a {
  cursor: pointer !important;
}
/* ----------------------
*	Loading
---------------------------*/
#loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $px-theme;
  z-index: 99999;
}
.load-circle {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 50px;
  height: 50px;
  span {
    display: inline-block;
    width: 64px;
    height: 64px;
    &:after {
      content: " ";
      display: block;
      width: 46px;
      height: 46px;
      margin: 1px;
      border-radius: 50%;
      border: 5px solid $px-white;
      border-color: $px-white transparent $px-white transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* ----------------------
*	owl 
---------------------------*/
.owl-dots {
  text-align: center;
  .owl-dot {
    display: inline-block;
    vertical-align: top;
    width: 10px;
    height: 10px;
    background: transparent;
    border: 1px solid $px-dark;
    @include transition(ease all 0.55s);
    border-radius: 50%;
    margin: 0 5px;
    &.active {
      background: $px-dark;
    }
  }
}

.owl-carousel {
  .owl-item {
    img {
      width: auto;
      max-width: 100%;
    }
  }
}

/* Page title
--------------------------------*/
.page-title {
  padding: 120px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @include down-lg {
    padding: 100px 0;
  }
  @include down-sm {
    padding: 80px 0;
  }

  h1 {
    font-weight: 700;
    margin-bottom: 15px;
    color: $px-white;
    @include down-md {
      font-size: 22px;
    }
  }
}
@include down-sm {
  .navbar-brand {
    //height: 28px;
  }
}

/* Breadcrumbs
--------------------------------*/
.breadcrumb {
  margin: 0;
  padding: 0;
  list-style: none;
  background: none;
  border-radius: 0;
  li {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 400;
    position: relative;
    color: $px-white;
    + li {
      padding-left: 17px;
      margin-left: 17px;
      &:after {
        content: "";
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: currentColor;
        opacity: 0.85;
        left: -2px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
    a {
      color: $px-theme;
    }
  }
}
